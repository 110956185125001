import * as React from 'react';
import styled from '@emotion/styled';
import Layout from '../components/layout';
import Seo from '../components/seo';

import {HomeSplash, RowWrapper, TextCenter} from './contact.module.css';

const GMaps =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.4358546634767!2d-122.53876828444899!3d49.192290779321574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5485d2d3f6948ca7%3A0xcbf75ad39e7c2293!2s10445%20McEachern%20St%2C%20Maple%20Ridge%2C%20BC%20V2W%201G5!5e0!3m2!1sen!2sca!4v1621284490764!5m2!1sen!2sca'; // google maps embed

const GStyle = {
  border: `0`,
};

const JotformUrl = 'https://form.jotform.com/92247478546267'; //Jotform embed URL from Iframe

const IStyle = {
  width: `100%`,
  height: `580px`,
  overflow: `hidden`, // hide scrolls
  scrolling: `no`,
  border: `none`,
  margin: `0 0 3em 0`,
  padding: `0`,
};

const Heading = styled.h1`
  color: var(--blue);
  text-align: center;
`;

const Contact = () => (
    <Layout>
    <Seo title='Contact Thornhill Plumbing'
         description='Plumbing and Renovations' />
    <div className={HomeSplash}>
      <iframe
          title='thornhill plumbing and renovations'
          src={GMaps}
          width='600'
          height='500'
          frameBorder='0'
          style={GStyle}
          allowFullScreen=''
          aria-hidden='false'
      />
    </div>

    <div className={RowWrapper} style={{marginTop: `3em`}}>
      <Heading>Thornhill Plumbing</Heading>
      <p className={TextCenter}>
        <strong>Alex Takach, Certified Plumber</strong><br />thornhillplumbingbc@gmail.com<br /><br />

10445 McEachern Street,<br />
Maple Ridge, BC<br />
V2W 0H8<br /><br />
        <a href='tel:+17788354512' title='Contact thornhill'>
        778.835.4512
        </a>
      </p>
    </div>

      {/* main text */}
      <div className={RowWrapper}>
      <iframe
          title='contact form'
          style={IStyle}
          src={JotformUrl}
          scrolling='no'
          allowFullScreen='true'
          allowTransparency='true'
          onLoad={() => {
            window.parent.scrollTo(0, 0);
          }}
      />
    </div>
  </Layout>
);

export default Contact;